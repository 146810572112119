// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
jQuery(document).foundation();


/* Fluidbox */

jQuery(function(){
	jQuery('a.fluidbox').fluidbox();
});


jQuery(document).ready(function() {
  return jQuery(document).on("click", ".title-bar, .js-off-canvas-exit", function() {
    return jQuery(".icon").toggleClass("active");
  });
});

jQuery('.title-bar').click(function(){ false });

/* Smooth Scroll */

jQuery(function($) {
  $('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

/* Sliders */

jQuery(function(){

	jQuery('.social-feed').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
		adaptiveHeight: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
		{
		  breakpoint: 1000,
		  settings: {
		    slidesToShow: 3
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
		    slidesToShow: 2
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
		    slidesToShow: 1
		  }
		}
		]
	});

	jQuery('.upcoming-slider').slick({
		arrows: false,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		autoplaySpeed: 5000
	});

	jQuery('.course-carousel').slick({
		arrows: true,
		autoplay: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
		{
		  breakpoint: 1000,
		  settings: {
		    slidesToShow: 5
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
		    slidesToShow: 4
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
		    slidesToShow: 2
		  }
		}
		]
	});

	jQuery('.course-carousel-widget').slick({
		arrows: false,
		autoplay: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplaySpeed: 6000,
		responsive: [
		{
		  breakpoint: 480,
		  settings: {
		    slidesToShow: 1
		  }
		}
		]
	});

	jQuery('.feedback-carousel').slick({
		arrows: false,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 6000,
		dots: true
	});

	jQuery('.team-carousel').slick({
		arrows: false,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 2000,
		dots: false,
		fade: true,
		lazyLoad: 'ondemand',
	});

	jQuery('.featured-courses').slick({
		autoplay: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
	});


	jQuery('#specific-courses-dropdown').change(function() {
        url = jQuery(this).val();
        window.location.href = url;
    });

	jQuery('#cat-slider').slick({
	  	slidesToShow: 1,
	  	centerMode: false,
	  	//centerPadding: '50px',
	    infinite: false,
	    arrows: true,
	    dots: false,
	    autoplay: false,
	    initialSlide: 0,
	    variableWidth: true,
	    swipeToSlide: true,
	});

});


/* Facebook Sharer */

function fbShare(url, title, descr, image, winWidth, winHeight) {
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

function googleShare(url, winWidth, winHeight) {
    window.open("https://plus.google.com/share?url=" + url + "", "", "width=" + winWidth + ", height=" + winHeight + "");
}


jQuery(document).ready(function() {

	jQuery( '<a href="https://www.stridingedgeconsultancy.co.uk/report-generator/" class="gform_previous_button button showit">Back</a>' ).insertAfter( '#gform_previous_button_7' );
	jQuery( '#gform_previous_button_7' ).hide();

});

/*

Modernizr.on('webp', function (result) {
  var hero = document.getElementById('webp-hero');
  if (result) {
    bgimg = hero.getAttribute('data-webp');
  }
  else {
    bgimg = hero.getAttribute('data-jpg');
  }
  $('#webp-hero').css('background-image', 'url(' + bgimg + ')');
});

*/

/* Gravity forms star rating */

(function($) {

	$(document).ready(function() {
		$('.js-gf-rating-stars').gformsRatings();
	});

	// The meat and potatoes
	$.fn.gformsRatings = function( options ) {

		// Setup options
		var settings = $.extend({
			'labels' : false,
			'activeClass' : 'gf-rating-star-active',
			'iconClass' : 'fas fa-star'
		}, options);

		return this.each(function() {

			// Store the object
			let $this = $(this),
				$container = $('.ginput_container', $this),
				$radioGroup = $('.gfield_radio', $this),
				$radios = $('input[type="radio"]', $radioGroup),
				$stars = null,
				$currentIndex = null;

			// Initialize
			let init = function() {

				$radioGroup.css('display', 'none');

				$wrap = $('<div class="gf-rating-stars"></div>');

				for (let i = 0; i < $radios.length; i++) {
					let label = $radios.eq(i).siblings('label').text(),
						value = $radios.eq(i).val(),
						$star = $('<i class="js-gf-rating-star gf-rating-star"></i>');

					$star
						.addClass(settings.iconClass)
						.data('index', i)
						.data('value', value)
						.data('label', label)
						.attr('title', label)
						.appendTo($wrap);

				}

				$wrap.appendTo($container);

				$stars = $('.js-gf-rating-star', $this); // Fix issue with multiple radio groups

				// Star Interactions
				$stars.on('mouseover', function() { // Mouseover instead of hover
					handleHover($(this));
				}).on('click', function() {
					handleClick($(this));
				}).on('mouseout', function() {
					handleOut($(this));
				});

				// Restore to currently checked next field
				$wrap.on('mouseout', function() {
					$checked = $radios.filter(':checked');

					if (!$checked.length) {
						$stars.removeClass(settings.activeClass);
					} else {
						highlight($currentIndex);
					}
				});
			}

			let highlight = function(index) {
				$stars.filter(':gt(' + index + ')').removeClass(settings.activeClass);
				$stars.filter(':lt(' + index + '), :eq(' + index + ')').addClass(settings.activeClass);
			}

			let handleHover = function(el) {
				let index = el.data('index');

				if (index >= $currentIndex) { // Do not apply to stars lesser than
					highlight(index);
				}
			}

			let handleOut = function(el) { // Empty selections if not clicked
				if ($currentIndex === null) {
					$stars.removeClass(settings.activeClass);
				}
			}

			let handleClick = function(el) {
				let index = el.data('index');
				highlight(index); // Highlight immediately after clicking
				$currentIndex = index;
				$radios.eq(index).trigger('click');
			}

			// Kick it off
			init();

		});

	};

})(jQuery);